import * as React from 'react';
import {
  Button,
  Platform,
} from 'react-native';
import * as Icon from '@expo/vector-icons';

import { createStackNavigator } from '@react-navigation/stack';
import ModalScreen from '../screens/ModalScreen';
import MainTabContainer from '../containers/MainTabContainer';
import NavigationHeaderStyle from '../constants/NavigationHeaderStyle';

const MainStack = createStackNavigator();
const ModalStack = createStackNavigator();

export default function Main() {
  return (
    <MainStack.Navigator
      screenOptions={ { presentation: 'modal' } }
    >
      <MainStack.Screen
        name="Tabs"
        component={MainTabContainer}
        options={{
          headerShown: false,
          title: 'Hem',
        }}
      />
      <ModalStack.Screen
        name="Modal"
        component={ModalScreen}
        options={({ route, navigation }) => {
          return {
            ...NavigationHeaderStyle,
            title: route.params?.title ?? '',
            headerLeft: () => {
              if (Platform.OS === 'ios') {
                return (
                  <Button
                    onPress={() => { navigation.pop() }}
                    title="Stäng"
                    color="#fff"
                  />
                )
              } else if (Platform.OS === 'web') {
                return (
                  <Icon.Ionicons
                    name="arrow-back"
                    size={26}
                    color="#fff"
                    style={{ marginLeft: 15 }}
                    onPress={() => { navigation.pop() }}
                  />
                )
              }
            },
            headerRight: () => {
              return Platform.OS === 'android' ? (
                <Icon.Ionicons
                  name="close"
                  size={26}
                  color="#fff"
                  style={{ marginRight: 15 }}
                  onPress={() => { navigation.pop() }}
                />
              ) : null;
            },
          }
        }}
      />
    </MainStack.Navigator>
  );
}
