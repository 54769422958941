import React from 'react';
import {
  StyleSheet,
  View,
  Switch,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { connect } from 'react-redux';
import debounce from 'debounce';

import * as Icon from '@expo/vector-icons';
import SearchInput from '../components/SearchInput';
import Text from '../components/Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { editJobsShowExternal, editJobsSearchQuery, fetchJobs, jobsCurrentPage } from '../actions/jobs';
import { fetchCategories } from '../actions/categories';
import { saveFilters } from '../actions/filters';
import { SecondaryButton } from '../components/Button';
import { selection as hapticSelection } from '../helpers/haptic';
import Modal from '../components/Modal';
import LineItemFoldOut from '../components/LineItemFoldOut';
import LineItem from '../components/LineItem';
import SearchParameters from '../constants/SearchParameters';
import { Category } from '../constants/types';
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

type District = {
  external_id: number;
  id: number;
  name: string;
  preselected?: boolean;
}

type Props = {
  filters: {
    office: keyof typeof SearchParameters,
    service: number[];
  };
  searchQuery: string;
  fetchCategories: typeof fetchCategories;
  saveFilters: typeof saveFilters;
  fetchJobs: typeof fetchJobs;
  jobsCurrentPage: typeof jobsCurrentPage;
  dirty: boolean;
  editJobsSearchQuery: typeof editJobsSearchQuery;
  editJobsShowExternal: typeof editJobsShowExternal;
  categories: Category[];
}

type State = {
  checkedCategoryFilters: number[];
  isSearchFocused: boolean;
  query: string;
  filterLocationModalVisible: boolean;
  filterCategoryModalVisible: boolean;
  showScoped: boolean;
  districtScope: boolean;
  selectedDistricts: District[];
  districtOptions: District[];
  districtError: boolean;
}

class JobsListHeaderContainer extends React.Component<Props, State> {

  state: State = {
    query: this.props.searchQuery,
    checkedCategoryFilters: this.props.filters.service,
    isSearchFocused: false,
    filterLocationModalVisible: false,
    filterCategoryModalVisible: false,
    showScoped: false,
    districtScope: true,
    selectedDistricts: [],
    districtOptions: [],
    districtError: false,
  };

  async componentDidMount() {
    const { fetchCategories } = this.props;
    await fetchCategories();
    await this.fetchOfficesOptions();

  }

  async fetchOfficesOptions() {
    try {
      const res = await GET('/offices/internal');
      this.setState({ districtOptions: res.json, selectedDistricts: res.json.filter(district => district.preselected) });
    } catch(e) {
      reportRequestError(e, { showAlert: false });
    }
  }

  onChangeDistrictFilter = (filterValue: number) => {
    const { selectedDistricts, districtOptions } = this.state;
    const district = districtOptions.find(option => option.id === filterValue);
    if (district) {

      if (selectedDistricts.find(selectedDistrict => selectedDistrict.id === filterValue)) {
        this.setState({ selectedDistricts: selectedDistricts.filter(district => district.id !== filterValue), districtError: false });
      } else {
        this.setState({ selectedDistricts: [...selectedDistricts, district], districtError: false });
      }
    }
  };

  onChangeCategoryFilter = (filterValue: number) => {
    const { checkedCategoryFilters } = this.state;
    if (checkedCategoryFilters.includes(filterValue)) {
      this.setState({ checkedCategoryFilters: [...checkedCategoryFilters].filter(item => item !== filterValue) });
    } else {
      this.setState({ checkedCategoryFilters: [...checkedCategoryFilters, filterValue] });
    }
  }

  onShowScopedSwitch = () => {
    this.setState({ showScoped: !this.state.showScoped }, () => this.onSubmitFilterSelection());
  }

  onDistrictScopeSwitch = () => {
    this.setState({ districtScope: !this.state.districtScope, districtError: false });
  }

  openLocationFilterModal = (): void => this.setState({ filterLocationModalVisible: true });
  openCategoryFilterModal = (): void => this.setState({ filterCategoryModalVisible: true });

  closeFilterModals = (): void => {
    this.setState({
      filterLocationModalVisible: false,
      filterCategoryModalVisible: false,
      checkedCategoryFilters: this.props.filters.service,
    });
  }

  clearDistricts = () => {
    this.setState({ selectedDistricts: [] });
  }

  onSubmitFilterSelection = async (): Promise<void> => {
    const { saveFilters, jobsCurrentPage, fetchJobs } = this.props;
    const { selectedDistricts, checkedCategoryFilters, showScoped, districtScope } = this.state;

    if (!districtScope && selectedDistricts.length === 0) {
      this.setState({ districtError: true });
    } else {
      saveFilters({ office: districtScope ? SearchParameters.MY_DISTRICT : SearchParameters.ENTIRE_COUNTRY, service: checkedCategoryFilters, scoped: showScoped, selectedOffices: selectedDistricts.map(district => district.id) });

      this.setState({ filterLocationModalVisible: false, filterCategoryModalVisible: false });
      jobsCurrentPage(1);
      await fetchJobs();
      hapticSelection();
    }
  }

  render() {
    const { dirty, categories } = this.props;
    const {
      query,
      isSearchFocused,
      checkedCategoryFilters,
      filterLocationModalVisible,
      filterCategoryModalVisible,
      showScoped,
      districtScope,
      selectedDistricts,
      districtOptions,
      districtError,
    } = this.state;

    return (
      <View style={ styles.container }>
        <View style={ styles.headerContainer }>
          <Text style={ styles.header }>
            Våra tillgängliga uppdrag
          </Text>
        </View>
        <View style={ styles.inputContainer }>
          <SearchInput
            value={ query }
            onChangeText={ this._onSearchTextChange }
            isFocused={ isSearchFocused }
            onFocus={ () => { this.setState({ isSearchFocused: true }); } }
            onBlur={ () => { this.setState({ isSearchFocused: false }); } }
            placeholder={ 'Sök efter jobb' }
          />
        </View>
        <View style={ styles.switchContainer }>
          <View style={ styles.switchTextContainer }>
            <Text style={ styles.switchText }>
              Visa bara uppdrag baserat på min profil
            </Text>
          </View>
          <Switch
            value={ showScoped }
            trackColor={ { true: Colors.primaryBackground } }
            onValueChange={ this.onShowScopedSwitch }
          />
        </View>
        { !showScoped &&
          <View style={ styles.filter }>
            <TouchableOpacity onPress={ this.openLocationFilterModal } style={ [styles.filterButtonWrapper, styles.filterButtonWrapperLeft] }>
              <View style={ styles.filterButton }>
                <Text style={ styles.filterButtonText }>
                  Välj plats
                </Text>
                <Icon.Ionicons
                  name={ 'arrow-forward' }
                  size={ 24 }
                  style={ styles.rightContentIcon }
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={ this.openCategoryFilterModal } style={ styles.filterButtonWrapper }>
              <View style={ styles.filterButton }>
                <Text style={ styles.filterButtonText }>
                  Filtrera tjänst
                </Text>
                <Icon.Ionicons
                  name={ 'options' }
                  size={ 24 }
                  style={ [styles.rightContentIcon, checkedCategoryFilters.length > 0 && { color: Colors.primaryBackground }] }
                />
              </View>
            </TouchableOpacity>
          </View>
        }
        {false && dirty &&
          <SecondaryButton title={ 'Rensa sökning' } onPress={ this._onClear } />
        }
        
        <Modal
          visible={ filterLocationModalVisible }
          title={ 'Välj plats' }
          secondaryTitle={ districtError && 'Du måste välja minst ett distrikt' }
          secondaryTitleStyles={ { color: Colors.errorText } }
          contentContainerStyles={ { maxHeight: '60%' } }
          onClose={ this.closeFilterModals }
          onSubmit={ this.onSubmitFilterSelection }
        >
          <>
            <View style={ [styles.switchContainer, { marginTop: 0, marginHorizontal: 32 }] }>
              <View style={ styles.switchTextContainer }>
                <Text style={ styles.switchText }>
                  Visa uppdrag från mitt distrikt
                </Text>
              </View>
              <Switch
                value={ districtScope }
                trackColor={ { true: Colors.primaryBackground } }
                onValueChange={ this.onDistrictScopeSwitch }
              />
            </View>
            {
              !districtScope &&
              <View style={ { marginHorizontal: 32 } }>
                <View style={ { flexDirection: 'row', justifyContent: 'space-between' } }>
                  <Text style={ { fontSize: 12, fontFamily: Fonts.emphasis, paddingVertical: 16 } }>
                    { 'Valda distrikt:' }
                  </Text>
                  {
                    selectedDistricts.length > 0 &&
                    <TouchableOpacity onPress={ () => this.clearDistricts() }>
                      <Text style={ { fontSize: 12, fontFamily: Fonts.emphasis, textDecorationLine: 'underline', paddingVertical: 16 } }>
                        { 'Rensa' }
                      </Text>
                    </TouchableOpacity>
                  }
                </View>
                <Text style={ { paddingBottom: 16 } }>
                  { selectedDistricts.length > 0 ? selectedDistricts.map(district => district.name).join(', ') : 'Inga distrikt valda' }
                </Text>
              </View>
            }
            <ScrollView style={ styles.districtOptionsContainer }>
              { !districtScope && districtOptions.map((option, i) =>
                <LineItem.Checkbox
                  key={ option.id }
                  label={ option.name }
                  value={ option.id }
                  containerStyles={ i === 0 && { borderTopWidth: 0 } }
                  checked={ !!selectedDistricts.find(district => district.id  === option.id) }
                  onChange={ this.onChangeDistrictFilter }
                />
              )}
            </ScrollView>
          </>
        </Modal>
        <Modal
          visible={ filterCategoryModalVisible }
          title={ 'Filtrera tjänst' }
          onClose={ this.closeFilterModals }
          onSubmit={ this.onSubmitFilterSelection }
        >
          <ScrollView>
            { categories.map(category => 
              <LineItemFoldOut
                key={ category.id }
                label={ category.name }
                subOptions={ category.services }
                checkedSubOptions={ checkedCategoryFilters }
                onChangeSubOption={ this.onChangeCategoryFilter }
              />
            )}
          </ScrollView>
        </Modal>
      </View>
    );
  }

  _onSearchTextChange = (query: string) => {
    this.setState({ query });
    this._submitSearchDebounced();
  }

  _submitSearch = () => {
    const { editJobsSearchQuery, jobsCurrentPage, fetchJobs } = this.props;
    const { query } = this.state;

    editJobsSearchQuery(query);
    jobsCurrentPage(1);
    fetchJobs();
  }

  _onChangeFilter = value => {
    const { editJobsShowExternal, jobsCurrentPage, fetchJobs } = this.props;

    editJobsShowExternal(value);
    jobsCurrentPage(1);
    fetchJobs();
    hapticSelection();
  }

  _onClear = () => {
    const { editJobsSearchQuery, editJobsShowExternal, jobsCurrentPage, fetchJobs } = this.props;

    this.setState({ query: '' });
    editJobsSearchQuery('');
    editJobsShowExternal(false);
    jobsCurrentPage(1);
    fetchJobs();
  }

  _submitSearchDebounced = debounce(this._submitSearch, 1500);

}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
  },
  headerContainer: {
    paddingTop: 24,
    paddingHorizontal: 24,
    marginBottom: 16,
  },
  header: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
  },
  headerSpan: {
    color: Colors.tintColor,
  },
  inputContainer: {
    marginHorizontal: 24,
  },
  switchContainer: {
    marginTop: 32,
    paddingVertical: 16,
    marginHorizontal: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchTextContainer: {
    marginRight: 16,
    flex: 1,
  },
  switchText: {
    fontSize: 13,
    fontFamily: Fonts.emphasis,
  },
  switchSubContainer: {
    marginTop: 4,
    marginRight: 24,
  },
  switchSubText: {
    fontSize: 14,
    color: Colors.secondaryText,
  },
  filter: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  filterButtonWrapper: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  filterButtonWrapperLeft: {
    borderRightWidth: 1,
  },
  filterButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  filterButtonText: {
    fontFamily: Fonts.emphasis,
  },
  rightContentIcon: {
    color: Colors.secondaryText,
    marginLeft: 12,
  },
  districtOptionsContainer: {
    borderTopWidth: 1,
    borderColor: Colors.border,
    height: 500,
  },
});

const mapState = state => {
  const { jobs, services, categories, filters } = state;

  return {
    jobCount: jobs.pagination.total,
    showExternal: jobs.showExternal,
    searchQuery: jobs.searchQuery,
    dirty: jobs.searchQuery || jobs.showExternal,
    services: services.items,
    categories: categories.categories,
    filters: filters,
  };
};

const mapDispatch = {
  editJobsShowExternal,
  editJobsSearchQuery,
  fetchJobs,
  jobsCurrentPage,
  fetchCategories,
  saveFilters,
};

export default connect(mapState, mapDispatch)(JobsListHeaderContainer);
