import React, { useState } from "react";
// import { SectionList } from "react-navigation";
import { Platform, SectionList } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import { connect } from "react-redux";
import { compose } from "redux";

import SectionHeader from "../components/SectionHeader";
import ListItem from "../components/ListItem";
import ListItemSeparator from "../components/ListItemSeparator";
import UserInfo from "../components/UserInfo";
import withLoader from "../components/withLoader";
import withFetchError from "../components/withFetchError";
import { fetchOfficesIfNeeded } from "../actions/offices";
import NotificationResourceTypes from "../constants/NotificationResourceTypes";
import { fetchUserIfNeeded } from "../actions/user";
import withFetch from "../components/withFetch";
import IdCardModal from "../components/IdCardModal";

const ProfileContainer = ({ userData, offices, payslipNotificationCount, navigation }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const ref = React.useRef(null);
  useScrollToTop(ref);

  const _renderSectionHeader = ({ section: { title } }) => {
    return <SectionHeader title={title} />;
  };

  const _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={item.title}
        subtitle={item.subtitle}
        icon={item.icon}
        attention={item.attention}
        onPress={_onPress(item)}
      />
    );
  };

  const _onPress = (item) => () => {
    if (item.onPress) {
      return item.onPress();
    }
    navigation.push(item.navigateTo, item.navigateToProps);
  };

  const _showModal = () => {
    setModalVisible(true);
  };
  const _closeModal = () => {
    setModalVisible(false);
  };

  const listSections = [
    {
      title: "Information",
      data: [
        {
          title: "Lönespecifikationer",
          subtitle: "Se ditt lönebesked",
          icon: "file-o",
          attention: payslipNotificationCount > 0,
          navigateTo: "Payslips",
        },
        {
          title: "Kontakta mitt lokalkontor",
          subtitle: "Kontaktuppgifter till ditt kontor",
          icon: "phone",
          navigateTo: offices.length > 1 ? "Offices" : "Office",
          navigateToProps:
            offices.length === 1
              ? {
                id: offices[0].id,
                title: offices[0].title ? offices[0].title.rendered : "",
              }
              : null,
        },
        {
          title: "ID-Kort",
          subtitle: "Ditt Veteranpoolen ID-kort",
          icon: "id-card",
          onPress: _showModal,
        },
        {
          title: "Information och arbetsmiljö",
          subtitle: "Information, arbetsmiljö och manualer",
          icon: "info",
          navigateTo: "InformationPages",
        },
      ],
    },
    {
      title: "Inställningar",
      data: [
        // {
        //   title: "Min jobbprofil",
        //   subtitle: "Gör din profil mer attraktiv",
        //   icon: "cog",
        //   navigateTo: "JobProfile",
        // },
        // {
        //   title: "Frånvaro/Ledighet",
        //   subtitle: "Anteckna om det är något ditt kontor behöver veta i samband med tilldelning av uppdrag, till exempel om du ska vara ledig, ha semester eller ej kan jobba för tillfället.",
        //   icon: "sticky-note",
        //   navigateTo: "NoteInput",
        // },
        {
          title: "Kontaktuppgifter",
          subtitle: "Ändra dina kontaktuppgifter",
          icon: "vcard",
          navigateTo: "ContactDetails",
        },
        {
          title: "Notiser",
          subtitle: "Ställ in push-notiser",
          icon: "dot-circle-o",
          navigateTo: "Notifications",
          hideInWeb: true,
        },
        {
          title: "Logga ut",
          subtitle: "Logga ut från vappen",
          icon: "sign-out",
          navigateTo: "LogOut",
        },
      ],
    },
  ];

  const filteredList = listSections.map(section => {
    if (Platform.OS === 'web') {
      return {
        ...section,
        data: section.data.filter(item => !item.hideInWeb),
      };
    } else {
      return section;
    }
  });

  return (
    <>
      <SectionList
        ref={ref}
        contentContainerStyle={{ paddingBottom: 24 }}
        ListHeaderComponent={
          <UserInfo
            name={`${userData.first_name} ${userData.last_name}`}
            employeeNumber={userData.number}
          />
        }
        keyExtractor={(item) => item.title}
        sections={filteredList}
        renderItem={_renderItem}
        renderSectionHeader={_renderSectionHeader}
        ItemSeparatorComponent={ListItemSeparator}
        stickySectionHeadersEnabled={false}
      />
      <IdCardModal
        modalVisible={modalVisible}
        userData={userData}
        onClose={_closeModal}
        office={offices.length > 0 && offices[0]}
      />
    </>
  );
}

const mapState = (state) => {
  const { user, offices, notifications } = state;

  return {
    loading: user.fetching || offices.fetching,
    fetchError: user.fetchError,
    userData: user.data,
    offices: offices.items,
    payslipNotificationCount: notifications.items.filter(
      (i) => i.data.resource_type === NotificationResourceTypes.Payslip
    ).length,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetch: () => {
      dispatch(fetchOfficesIfNeeded());
      dispatch(fetchUserIfNeeded());
    },
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withLoader,
  withFetchError,
  withFetch
)(ProfileContainer);
