import { Alert, Platform } from 'react-native';
import * as SentryNative from '@sentry/react-native';
import * as SentryBrowser from '@sentry/browser';

const SentryInstance = Platform.OS === 'web' ? SentryBrowser : SentryNative;

export const reportRequestError = (response, { showAlert = true } = {}) => {
  const { status } = response;

  if (status !== 401) {
    SentryInstance.captureException(`HTTP Error ${ response.status }`, { extra: { response } });
  }

  if (showAlert) {
    presentAlert(status);
  }
};

export const reportResponsePayloadError = (error, response) => {
  SentryInstance.captureException(error, {
    extra: { response },
  });
};

const presentAlert = status => {
  let title = 'Nätverksfel';
  let text = 'Vappen kunde inte slutföra anropet, kontrollera din anslutning till Internet.';

  if (status) {
    title = 'Ett fel uppstod';
    text = `Felkod ${ status }. Vappen kunde inte hantera anropet.`;
  }
  if (Platform.OS === 'web') {
    window.alert(`${ title } - ${ text }`);
  } else {
    Alert.alert(title, text);
  }

};
