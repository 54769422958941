import React from 'react';
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View } from 'react-native';
import * as Icon from '@expo/vector-icons';
import moment from 'moment';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import Text from './Text';

type Props = {
  title: string;
  subtitle: string;
  subsubtitle?: string,
  date: string;
  attention?: boolean;
  id?: number;
  onPress: () => void;
  applied: boolean;
  approved: boolean;
  icon?: string;
  image?: string;
  hint?: string;
}

const ListItem = (props: Props) => {
  const {
    title,
    subtitle,
    subsubtitle,
    hint,
    attention,
    date,
    icon,
    image,
    id,
    onPress,
    applied,
    approved,
  } = props;

  const caret = Platform.OS === 'ios';
  const web = Platform.OS === 'web';
  return (
    <TouchableOpacity onPress={ onPress }>
      <View style={ styles.container }>
        {(!!icon || !!image) &&
          <View style={ styles.leftContent }>
            {!!icon &&
              <Icon.FontAwesome
                name={ icon }
                size={ icon === 'vcard' ? 18 : 24 }
                style={ styles.leftContentIcon }
              />
            }
            {!!image &&
              <Image
                style={ styles.image }
                source={ { uri: image } }
              />
            }
          </View>
        }

        <View style={ styles.content }>
          {!!id &&
            <Text style={ styles.id }>
              {id}
            </Text>
          }
          <Text style={ styles.title }>
            {title}
          </Text>
          {!!subtitle &&
            <Text style={ styles.subtitle }>
              {subtitle}
            </Text>
          }
          {!!subsubtitle &&
            <Text style={ styles.subtitle }>
              {subsubtitle}
            </Text>
          }
          {!!date &&
            <Text style={ styles.date }>
              {`${ moment(date).fromNow().toUpperCase() } (${ moment(date).format('YYYY-MM-DD HH:mm') })`}
            </Text>
          }
        </View>

        {(hint || caret || web || applied || approved) &&
          <View style={ styles.rightContent }>
            { !!applied && 
              <View>
                <View style={ styles.appliedTag }>
                  <Text style={ styles.appliedTagText }>
                    Ansökt
                  </Text>
                </View>
              </View>
            }
            { !!approved && 
              <View>
                <View style={ styles.appliedTag }>
                  <Text style={ styles.appliedTagText }>
                    Tilldelad
                  </Text>
                </View>
              </View>
            }
            { !!hint &&
              <View style={ styles.hintContainer }>
                <Text style={ styles.hint }>
                  { hint }
                </Text>
              </View>
            }
            { !!attention &&
              <View style={ styles.attention }></View>
            }
            { !!caret &&
              <Icon.Ionicons
                name={ 'arrow-forward' }
                size={ 24 }
                style={ styles.rightContentIcon }
              />
            }
          </View>
        }
      </View>
    </TouchableOpacity>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
    flexDirection: 'row',
  },
  id: {
    fontFamily: Fonts.medium,
  },
  title: {
    fontFamily: Fonts.bold,
  },
  subtitle: {
    color: Colors.secondaryText,
    fontFamily: Fonts.regular,
  },
  hintContainer: {
    marginTop: 16,
  },
  hint: {
    color: Colors.secondaryText,
    marginTop: -3,
  },
  attention: {
    marginTop: -3,
    borderRadius: 14/2,
    width: 14,
    height: 14,
    backgroundColor: Colors.attentionColor,
  },
  date: {
    color: Colors.secondaryText,
    fontFamily: Fonts.regular,
    fontSize: 12,
    marginTop: 12,
  },
  content: {
    flex: 1,
  },
  leftContent: {
    alignSelf: 'flex-start',
    marginRight: 10,
    marginTop: 4,
  },
  rightContent: {
    flexDirection: 'row',
  },
  leftContentIcon: {
    color: Colors.tintColor,
  },
  rightContentIcon: {
    color: Colors.secondaryText,
    marginLeft: 16,
    marginTop: 12,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 40/2,
  },
  appliedTag: {
    marginTop: 12,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: Colors.tintColor,
  },
  appliedTagText: {
    fontFamily: Fonts.emphasis,
    fontSize: 12,
    color: Colors.veryWhite,
  },
});

