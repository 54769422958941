import React from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import * as Icon from '@expo/vector-icons';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import StandardTextStyle from '../constants/StandardTextStyle';

export default (props) => (
  <View style={[styles.container, props.isFocused ? styles.focused : {}]}>
    <Icon.Ionicons
      name={'search'}
      size={20}
      style={styles.icon}
    />
    <TextInput
      style={styles.text}
      underlineColorAndroid="transparent"
      returnKeyType="search"
      clearButtonMode="always"
      placeholderTextColor={Colors.secondaryText}
      {...props}
    />
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    paddingRight: 8,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: Colors.borderDark,
  },
  focused: {
    borderColor: Colors.borderFocus,
  },
  text: {
    ...StandardTextStyle,
    lineHeight: 20,
    flex: 1,
  },
  icon: {
    color: Colors.secondaryText,
    marginRight: 8,
  },
});

