import React from 'react';
import {
  View,
  Modal,
  Text,
  Dimensions,
  TouchableWithoutFeedback,
  Image,
  StyleSheet,
  Platform,
} from 'react-native';
import LogoFile from '../assets/images/logo-white.png';
import * as Icon from '@expo/vector-icons';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const cardWidth = Platform.OS === 'web' ? 250 : Dimensions.get('screen').width - 20;
const cardHeight = cardWidth * 1.586;

export default ({ modalVisible, onClose, userData, office }) => {

  const {
    first_name,
    last_name,
    number,
    address,
    city,
    postal_code
  } = userData;
  return (
    <Modal
      visible={ modalVisible }
      transparent
      animationType={ 'fade' }
    >
      <View style={ styles.container }>
        <View style={ [styles.subContainer, Platform.OS === 'web' && styles.webSubContainer] }>
          <View style={ [styles.content, Platform.OS === 'web' && styles.webContent] }>
            <Image
              style={ styles.image }
              source={LogoFile}
            />

            <View style={ { height: 16 } }/>
            <Text style={ styles.primaryText }>
              { `${first_name} ${last_name}` }
            </Text>

            <View style={ { height: 16 } }/>
            <Text style={ styles.secondaryText }>
              { `${ address },\n${ postal_code } ${ city }` }
            </Text>

            <View style={ { height: 16 } }/>
            <Text style={ styles.secondaryText }>
              Anställningsnummer: { number }
            </Text>

            <View style={ { height: 16 } }/>
            <Text style={ styles.titleText }>
              Kontor:
            </Text>
            { office.title &&
              <Text style={ styles.secondaryText }>
                { office.title.rendered }
              </Text>
            }
            <Text style={ styles.secondaryText }>
              { office.phone }
            </Text>

          </View>
          <View style={ [styles.closeBtn, Platform.OS === 'web' && styles.webCloseBtn] }>
            <TouchableWithoutFeedback onPress={ onClose }>
              <Icon.Ionicons
                name='close'
                size={30}
                style={ styles.icon }
              />
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subContainer: {
    alignItems: 'center',
    backgroundColor: Colors.primaryBackground,
    width: cardWidth,
    height: cardHeight,
    borderRadius: 15,
    justifyContent: 'center',
  },
  webSubContainer: {
    width: cardHeight,
    height: cardWidth,
  },
  content: {
    transform: [{ rotate: '90deg' }],
    height: cardWidth,
    width: cardHeight,
    padding: 26,
  },
  webContent: {
    transform: [{ rotate: '0deg' }],
    height: cardWidth,
    width: cardHeight,
  },
  image: {
    width: '60%',
    resizeMode: 'contain',
  },
  primaryText: {
    fontWeight: 'bold',
    fontSize: 24,
    color: Colors.primaryText,
    fontFamily: Fonts.medium,
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.primaryText,
    fontFamily: Fonts.medium,
  },
  secondaryText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: Colors.primaryText,
    fontFamily: Fonts.medium,
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 24,
    zIndex: 1,
  },
  webCloseBtn: {
    top: 0,
    bottom: 'initial',
  },
  icon: {
    color: Colors.primaryText,
  },
})
