import React from 'react';
import { View, StyleSheet } from 'react-native';
import * as Icon from '@expo/vector-icons';

import Text from './Text';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

export default ({ title, text, success }) => (
  <View style={styles.container}>
    {success &&
      <Icon.Ionicons
        name="checkmark-circle-outline"
        size={60}
        style={styles.icon}
        color={Colors.successColor}
      />
    }
    {success === false &&
      <Icon.Ionicons
        name="close-circle-outline"
        size={60}
        style={styles.icon}
      />
    }
    <Text style={styles.title}>
      {title}
    </Text>
    <Text style={styles.text}>
      {text}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  icon: {
    textAlign: 'center',
    marginBottom: 4,
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 18,
    textAlign: 'center',
  },
  text: {
    color: Colors.secondaryText,
    textAlign: 'center',
  },
});

